<template>
    <b-modal 
        id="card-machine-modal" 
        hide-header 
        hide-footer 
        centered
        size="md"
        @hidden="onHidden"
    >
        <div class="modal-header">
            <p class="modal-title">{{ form.id ? 'Atualizar' : 'Criar' }} máquina</p>
            <Close class="icon" @click="close" />
        </div>
        <main class="content">
            <b-row>
                <b-col class="col-12">
                    <b-form-group>
                        <label for="machine_name">Fornecedor da máquina</label>
                        <b-form-input
                            id="machine_name"
                            type="text"
                            v-model="form.name"
                            placeholder="ex: Rede"
                        />
                        <div v-if="validate && !form.name" class="custom-invalid-feedback">Campo obrigatório</div>
                    </b-form-group> 
                </b-col>
            </b-row>
        </main>
        <div class="wrapper-button">
            <b-button
                variant="outlined-default"
                @click="close"
            >
                Cancelar
            </b-button>
            <b-button
                variant="primary"
                :disabled="loading"
                :loading="loading"
                @click="form.id ? updateCardMachine() : createCardMachine()"
            >
                Salvar
            </b-button>
        </div>
    </b-modal>
</template>
<script>
import api from '../api'
import { getCurrentClinic } from '@/utils/localStorageManager'
export default {
    props: {
        cardMachine: Object
    },
    components: {
        Close: () => import('@/assets/icons/close.svg'),
    },
    data() {
        return {
            loading: false,
            clinic: getCurrentClinic(),
            validate: false,
            form: {},
        }
    },
    methods: {
        close() {
            this.$bvModal.hide('card-machine-modal')
            this.$emit('hidden')
        },
        onHidden() {
            this.form = {}
            this.validate = false
        },
        async createCardMachine() {
            this.validate = true
            if (!this.form?.name) return
            this.loading = true
            try {
                await api.createCardMachine({
                    clinic_id: this.clinic.id,
                    name: this.form.name
                })
                this.$toast.success('Máquina criada com sucesso!')
                this.close()
            } catch(err) {
                this.$toast.error(err.message)
            } finally {
                this.loading = false
            }
        },
        async updateCardMachine() {
            this.validate = true
            if (!this.form?.name) return
            this.loading = true
            try {
                await api.updateCardMachine(this.form.id, {
                    clinic_id: this.clinic.id,
                    name: this.form.name
                })
                this.$toast.success('Máquina atualizada com sucesso!')
                this.close()
            } catch(err) {
                this.$toast.error(err.message)
            } finally {
                this.loading = false
            }
        }
    },
    watch: {
        cardMachine(val) {
            this.form = val || {}
        }
    }
}
</script>

<style lang="scss">
#card-machine-modal {
  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }

      .content {
        padding: 2rem;
      }

      .wrapper-button {
        width: 100%;
        display: inline-flex;
        padding: 0  2rem 1rem 2rem;
        justify-content: flex-end;
        gap: 2rem;
      }
    }
  }
}
</style>